import React, { useState } from 'react'
import { get } from 'lodash'
import { useSelector, useDispatch } from 'react-redux'
import {
  Button,
  Checkbox,
  Label,
  ColorPicker,
  ConfirmationPopup,
  DescriptionTable,
  Notification,
  PermissionMissingNotificationTitle,
  TextArea
} from 'shared/components'
import {
  sendErrorReport,
  getPortalData,
  checkUserPermission,
  getLink,
  getDisabledMessage,
  isFeatureAvailable,
  isFeatureEnabled,
  getUserPortalURL
} from 'shared/helpers'
import { initialPortalData, userPermissions, platformFeatures } from 'shared/constants'
import { updatePortalData } from 'src/company/actions'
import { updateCompany } from 'src/account/actions'
import './styles.scss'

const UserPortalData = () => {
  const canManageSettings = checkUserPermission(userPermissions.settings_write)
  const dispatch = useDispatch()
  const companyID = useSelector(state => get(state, 'company.details.id'))
  const companyName = useSelector(state => get(state, 'company.details.name'))
  const portalDomain = useSelector(state => get(state, 'company.details.portal_domain'))
  const userPortalPreferences = useSelector(state => get(state, 'company.userPortalPreferences'))
  const portalData = get(userPortalPreferences, 'portal_data')

  const [isReseting, setReseting] = useState(false)
  const [isLoading, setLoading] = useState(false)
  const [isResetToDefaultDisplayed, setResetToDefaultDisplay] = useState(false)
  const [topHeaderBckg, setTopHeaderBckg] = useState(
    getPortalData(portalData, 'topHeaderBackground', initialPortalData)
  )
  const [sidebarBckg, setSidebarBckg] = useState(
    getPortalData(portalData, 'sidebarBackground', initialPortalData)
  )
  const [pageBckg, setPageBckg] = useState(
    getPortalData(portalData, 'pageBackground', initialPortalData)
  )
  const [showDownloadLinks, setShowDownloadLinks] = useState(
    getPortalData(portalData, 'showProductLinks', initialPortalData)
  )
  const [allowLicenseReset, setAllowLicenseReset] = useState(
    getPortalData(portalData, 'allowUsersLicenseReset', initialPortalData)
  )
  const [allowLicenseBorrow, setAllowLicenseBorrow] = useState(
    getPortalData(portalData, 'allowUsersLicenseBorrow', initialPortalData)
  )
  const [hideCustomFields, setHideCustomFields] = useState(
    getPortalData(portalData, 'hideCustomFields', initialPortalData)
  )
  const [hideProductFeatures, setHideProductFeatures] = useState(
    getPortalData(portalData, 'hideProductFeatures', initialPortalData)
  )
  const [loginTitleText, setLoginTitleText] = useState(
    getPortalData(portalData, 'loginTitle', initialPortalData, '')
  )
  const [loginMessageText, setLoginMessageText] = useState(
    getPortalData(portalData, 'loginMessage', initialPortalData, '')
  )

  const createPortalData = () => {
    const data = {
      topHeaderBackground: topHeaderBckg,
      sidebarBackground: sidebarBckg,
      pageBackground: pageBckg,
      showProductLinks: showDownloadLinks,
      allowUsersLicenseReset: allowLicenseReset,
      allowUsersLicenseBorrow: allowLicenseBorrow,
      hideCustomFields: hideCustomFields,
      hideProductFeatures: hideProductFeatures,
      loginTitle: loginTitleText,
      loginMessage: loginMessageText
    }
    return data
  }

  const handlePortalDataChange = () => {
    const changedData = createPortalData()
    const data = { portal_data: JSON.stringify(changedData) }
    setLoading(true)

    updateCompany(companyID, data)
      .then(() => {
        setLoading(false)
        dispatch(updatePortalData({ ...userPortalPreferences, portal_data: changedData }))
        Notification('success', __('Changes saved successfully'))
      })
      .catch(err => {
        sendErrorReport(err, 'Cannot update user portal preferences', data)
        setLoading(false)
        Notification(
          'error',
          __('Your changes were not saved'),
          __('There was an error while saving your changes')
        )
      })
  }

  const handleResetToDefault = () => {
    const data = { portal_data: JSON.stringify(initialPortalData) }
    setReseting(true)

    updateCompany(companyID, data)
      .then(() => {
        setReseting(false)
        setTopHeaderBckg(initialPortalData.topHeaderBackground)
        setSidebarBckg(initialPortalData.sidebarBackground)
        setPageBckg(initialPortalData.pageBackground)
        setShowDownloadLinks(initialPortalData.showProductLinks)
        setAllowLicenseReset(initialPortalData.allowUsersLicenseReset)
        setAllowLicenseBorrow(initialPortalData.allowUsersLicenseBorrow)
        setLoginMessageText(initialPortalData.loginMessage)
        setLoginTitleText(initialPortalData.loginTitle)
        setResetToDefaultDisplay(false)
        dispatch(updatePortalData({ ...userPortalPreferences, portal_data: initialPortalData }))
        Notification('success', __('Changes saved successfully'))
      })
      .catch(err => {
        sendErrorReport(err, 'Cannot update user portal preferences', data)
        setReseting(false)
        Notification(
          'error',
          __('Your changes were not saved'),
          __('There was an error while saving your changes')
        )
      })
  }

  const handleManageSettingsClick = cb => {
    if (!canManageSettings) {
      Notification(
        'error',
        <PermissionMissingNotificationTitle permission={userPermissions.settings_write} />,
        __('Contact you account admin for support.')
      )
      return false
    }
    cb(true)
    return true
  }

  const getDomainData = () => {
    if (portalDomain) {
      return {
        link: getLink(portalDomain),
        domain: portalDomain
      }
    }

    const url = getUserPortalURL()
    const { hostname } = new URL(url)
    return {
      link: url,
      domain: hostname
    }
  }
  const userPortal = getDomainData()

  return (
    <div className='UserPortalData'>
      <div className='UserPortalData-domain'>
        <Label text={__('Portal domain')} inputId='portal-domain' />
        <a href={userPortal.link} target='_blank' rel='noopener noreferrer' className='domain'>
          {userPortal.domain}
        </a>
      </div>
      <div className='config-section'>
        <div className='section'>
          <Label
            inputId='logn-title-text-input'
            text={__('Portal title')}
            description={__(
              'Enter a personalized title for your user portal to be displayed on login view and in the browser tab.'
            )}
          />
          <TextArea
            disabled={isLoading}
            handleChange={val => {
              setLoginTitleText(val)
            }}
            id='logn-title-text-input'
            type='logn-title-text'
            value={loginTitleText}
            placeholder={`${companyName} ${__('User Portal')}`}
            rows='1'
          />
        </div>
        <div className='section'>
          <Label
            inputId='logn-message-text-input'
            text={__('Login message')}
            description={__(
              'Enter a personalized welcome message for users logging into your whitelabeled user portal. This message can include instructions, greetings, or any information you deem important for your users to know before they log in.'
            )}
          />
          <TextArea
            disabled={isLoading}
            handleChange={val => {
              setLoginMessageText(val)
            }}
            id='logn-message-text-input'
            type='logn-message-text'
            value={loginMessageText}
            placeholder={__('Personalized welcome message to be displayed on login view.')}
            rows='4'
          />
        </div>
      </div>
      <div className='style-config config-section'>
        <div className='section-header'>{__('Colors')}</div>
        <DescriptionTable
          details={[
            {
              label: __('Header'),
              value: (
                <ColorPicker
                  initialColor={topHeaderBckg}
                  onColorSelect={val => setTopHeaderBckg(val)}
                  label={topHeaderBckg}
                  featureEnabled={isFeatureEnabled(platformFeatures.extra_whitelabeling)}
                  featureAvailable={isFeatureAvailable(platformFeatures.extra_whitelabeling)}
                  notEnabledMessage={getDisabledMessage()}
                />
              )
            },
            {
              label: __('Sidebar'),
              value: (
                <ColorPicker
                  initialColor={sidebarBckg}
                  onColorSelect={val => setSidebarBckg(val)}
                  label={sidebarBckg}
                  featureEnabled={isFeatureEnabled(platformFeatures.extra_whitelabeling)}
                  featureAvailable={isFeatureAvailable(platformFeatures.extra_whitelabeling)}
                  notEnabledMessage={getDisabledMessage()}
                />
              )
            },
            {
              label: __('Page'),
              value: (
                <ColorPicker
                  initialColor={pageBckg}
                  onColorSelect={val => setPageBckg(val)}
                  label={pageBckg}
                  featureEnabled={isFeatureEnabled(platformFeatures.extra_whitelabeling)}
                  featureAvailable={isFeatureAvailable(platformFeatures.extra_whitelabeling)}
                  notEnabledMessage={getDisabledMessage()}
                />
              )
            }
          ]}
        />
      </div>
      <div className='main-config config-section'>
        <Checkbox
          label={__('Show product download links on license details page')}
          inputId='download-links'
          checked={showDownloadLinks}
          handleChange={val => {
            setShowDownloadLinks(val)
          }}
        />
        <Checkbox
          label={__('Allow users to reset licenses')}
          inputId='allow-license-reset'
          checked={allowLicenseReset}
          handleChange={val => {
            setAllowLicenseReset(val)
          }}
        />
        <Checkbox
          label={__('Allow users to borrow licenses')}
          inputId='allow-license-borrow'
          checked={allowLicenseBorrow}
          handleChange={val => {
            setAllowLicenseBorrow(val)
          }}
        />
        <Checkbox
          label={__('Hide product features')}
          inputId='hide-product-features'
          checked={hideProductFeatures}
          handleChange={val => {
            setHideProductFeatures(val)
          }}
        />
        <Checkbox
          label={__('Hide custom fields')}
          inputId='hide-custom-fields'
          checked={hideCustomFields}
          handleChange={val => {
            setHideCustomFields(val)
          }}
        />
      </div>
      <div className='UserPortalData-actions'>
        <Button
          theme='success'
          onClick={() => handleManageSettingsClick(handlePortalDataChange)}
          disabled={isLoading}
        >
          {__('Save changes')}
        </Button>
        <Button
          theme='default'
          onClick={() => handleManageSettingsClick(setResetToDefaultDisplay)}
          disabled={isLoading}
        >
          {__('Reset to default')}
        </Button>
      </div>
      {isResetToDefaultDisplayed && (
        <ConfirmationPopup
          closeCb={() => setResetToDefaultDisplay(false)}
          confirmCb={handleResetToDefault}
          title={__('Are you sure you want to reset user portal customizations to default values?')}
          confirmText={__('Reset to default')}
          theme='error'
          disabled={isReseting}
        />
      )}
    </div>
  )
}

export default UserPortalData
